import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1>404. Página no encontrada</h1>
    <p>Por algún motivo está pagina no ha podido ser enontrada.</p>
  </Layout>
)

export default NotFoundPage
